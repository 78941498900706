@import url('https://fonts.googleapis.com/css?family=Poppins');

* {
  font-family: "poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
.app {
  width: 100%;
  min-height: 100vh;
  background: #D9FDFF;

}

.parent {
  padding: 2rem;
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0 3px 9px #0005;

}

.file-upload {
  flex: 1;
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
}

.file-upload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}

.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.clear-document-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f44336;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: buttonFadeIn 1s forwards;
}

.clear-document-button:hover {
  background-color: #d32f2f;
  animation: buttonShake 0.5s infinite;
}

.clear-document-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(244, 67, 54, 0.3);
}

.clear-document-button svg {
  margin-right: 8px;
}

@keyframes buttonFadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonShake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(5px);
  }

  60% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}
.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #1976d2;
}

.upload-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.3);
}

.upload-button svg {
  margin-right: 8px;
}



.file-icon {
  color: blue;
  transition: color 0.3s ease;
}

.file-icon:hover {
  color: red;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animation-enter {
  opacity: 0;
}

.animation-enter-active {
  opacity: 1;
  animation: fadeIn 0.5s ease-in;
}

.animation-exit {
  opacity: 1;
}

.animation-exit-active {
  opacity: 0;
  animation: fadeIn 0.5s ease-out;
}


.points {
  text-align: justify;

}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container-1 {
  border-radius: 15px;
  padding: 15px;
  max-width: 960px;
  margin: 0 auto;
}


















