@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.invoice-form {
  font-family: "Poppins", sans-serif;

}

.invoice-form .popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  font-size: 0.9rem;
  z-index: 100;
}


.invoice-form .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333e;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow-y: auto;
  
}

.invoice-form .popup-content {
  width: 90%;
  max-width: 980px;
  margin-block: 2rem;

}


.invoice-form .invoice-form-container{
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.invoice-form .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  border-radius: 20%;
  padding: 7px;
}

.invoice-form .close:hover {
  color: #333;
}

.invoice-form h2 {
  margin-bottom: 20px;
}

.invoice-form .form-group {
  margin-bottom: 20px;
}

.invoice-form label {
  display: block;
  margin-bottom: 5px;
}

.invoice-form input[type="text"],
.invoice-form input[type="email"],
.invoice-form input[type="tel"],
.invoice-form input[type="number"],
.invoice-form textarea,
.invoice-form select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.invoice-form input[type="text"]:focus,
.invoice-form input[type="email"]:focus,
.invoice-form input[type="tel"]:focus,
.invoice-form input[type="number"]:focus,
.invoice-form textarea:focus,
.invoice-form select:focus {
  border-color: #007bff;
}

.invoice-form .registration-fee-input {
  display: flex;
  align-items: center;
}

.invoice-form .registration-fee-input select {
  margin-left: 10px;
  padding: 9px;
}

.invoice-form button[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: auto;
  display: table;
}

.invoice-form button[type="submit"]:hover {
  background-color: #0056b3;
  margin: auto;
  display: table;
}

.invoice-form .currency-container {
  display: flex;
  
  justify-content: space-around;
  
}

.invoice-form .currency-radio {
  display: inline-block;
}

.invoice-form .currency-label {
  display: inline-block;
  margin-left: 5px;
}



@media (max-width: 991px) {
  .invoice-form .popup-content {
    margin: auto;
  }

}